import type {NextPage} from 'next';
import PageWithLayoutType from '../types/pageWithLayout';
import Page from '../layouts/Page';
import DialogMenu from '@/components/Dialog/DialogMenu';
import {ReactElement, ReactNode, useCallback, useEffect, useState} from 'react';
import HomeSvg from '../assets/svgs/Home.svg';
import WorkSvg from '../assets/svgs/Work.svg';
import TimeSvg from '../assets/svgs/Time.svg';
import CakeSvg from '../assets/svgs/Cake.svg';
import PhotoSvg from '../assets/svgs/Photo.svg';

import {LINE} from '@/constants/line';
import {cls} from '@/utils';
import logo from '../assets/images/logo.png';
import Image from 'next/image';
import {NextSeo} from 'next-seo';
export interface dataParam {
  id: number;
  title: string;
  type: string;
  color: string;
  contents: {question: string; answer: string}[];
  images: Array<string>;
}

interface Props {
  data: dataParam[];
}

const Home: NextPage<Props> = (props) => {
  // console.log(props);

  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<dataParam>({
    id: 0,
    title: '',
    type: '',
    color: '',
    contents: [{question: '', answer: ''}],
    images: [],
  });

  const Menu = useCallback(({data}: {data: dataParam}) => {
    let SvgIcon;
    if (data.id === 1) {
      SvgIcon = <TimeSvg className={`mb-2 h-7 w-7`} />;
    } else if (data.id === 2) {
      SvgIcon = <WorkSvg className={`mb-2 h-7 w-7`} />;
    } else if (data.id === 3) {
      SvgIcon = <CakeSvg className={`mb-2 h-7 w-7`} />;
    } else if (data.id === 4) {
      SvgIcon = <HomeSvg className={`mb-2 h-7 w-7`} />;
    } else if (data.id === 5) {
      SvgIcon = <PhotoSvg className={`mb-2 h-7 w-7`} />;
    } else if (data.id === 6) {
      SvgIcon = <PhotoSvg className={`mb-2 h-7 w-7`} />;
    }

    return (
      <div
        onClick={() => {
          setData(data);
          setVisible((prev) => !prev);
        }}
        style={{
          backgroundColor: data.color,
        }}
        className={cls(
          `flex flex-1 flex-col items-center justify-center rounded-lg  text-white `,
        )}
      >
        {SvgIcon}
        <div>{data.title}</div>
      </div>
    );
  }, []);

  const onClickCancel = useCallback(() => {
    setVisible((prev) => !prev);
  }, []);

  return (
    <>
      <NextSeo
        title="라인친구"
        description="여성알바 라인친구 여성고소득 고수익 알바 유흥알바 밤알바 룸알바 퀸알바 여우알바 24시간 영업중입니다. "
        canonical="https://www.line-79.com"
        openGraph={{
          url: 'https://www.line-79.com',
          images: [
            {
              url: 'https://www.line-79.com/logo.png',
              width: 1200,
              height: 630,
              alt: '라인친구',
            },
          ],
        }}
      />
      <div className="flex h-full  flex-col  ">
        <div className="mt-10 flex justify-center">
          <Image alt="logo" src={logo} width={120} />
        </div>
        {/* <div className="pt-5 text-center text-4xl">라인</div> */}
        <div className="mt-7 space-y-2 bg-[#323351] px-5 py-5">
          <div className=" flex h-32 flex-row justify-between space-x-2">
            {/* <div
            onClick={() => setVisible((prev) => !prev)}
            className="flex flex-1 flex-col items-center justify-center rounded-lg bg-[#6F51D5] text-white underline"
          >
            <div>24시간</div>
            <div>영업중 !</div>
          </div> */}
            <Menu data={props.data[0]} />
            {/* <Menu data={props.data[1]} /> */}
            <Menu data={props.data[2]} />

            {/* <div className="flex flex-1 flex-col items-center justify-center rounded-lg bg-[#4892F1] text-white underline">
            <div>복지</div>
            <div>지원 최고!</div>
          </div> */}
          </div>

          <div className=" flex h-32 flex-row justify-between space-x-2">
            <Menu data={props.data[3]} />
            {/* <Menu data={props.data[4]} /> */}
            <Menu data={props.data[5]} />
          </div>

          {/* <div className=" flex h-32 flex-row justify-between space-x-2">
          <div className="flex flex-1"></div>
          <Menu data={props.data[6]} />
        </div> */}
        </div>

        <DialogMenu
          visibleDialog={visible}
          data={data}
          // onClickOk={(text) => {
          //   // setVisible((prev) => !prev);
          // }}
          onClickCancel={onClickCancel}
        />
      </div>
    </>
  );
};

(Home as PageWithLayoutType).layout = Page;

export default Home;

export async function getStaticProps() {
  return {
    props: {
      data: LINE,
    },
  };
}

// 1. 티씨
// 2. 위치
// 3. 이벤트
// 4. 일종류
// 5. 홀복 /구두 / 대기실 /홀복등

// 6. 갯수등

// 당일지급/ 초보환영 / 경력우대 / 투잡알바
// 송파구 가락동

// 위치
// 송파구 가락동

// 1. 24시간 영업

// Q. 출근이 자유로운 편인가요?
// 출근하셔서 하고싶은 만큼 자유롭게 일하시면 되요!
// 원할때 언제든 원하는 만큼 체력 닿는데까지 일잡아 드릴꼐요!

// Q. 일은 얼마나 있죠?
//오전, 오후에도 일, 콜 많고 주간조 언니들 많아요!
//일이 너무 많아서 손님대비 언니들이 부족해요!

// 2. 페이(당일지급)

// Q. 페이는 어떻게 지급되나요?
// 미수 상관없이 무조건 100% 당일지급 원칙
// 한티 기준 13만원 / 옵션 20만원
// 갯수내용추가

// 3. 복지 지원 최고!

// Q. 일할때 지원해 주는게 있나요?
// 택시비 횟수 상관없이 무제한 지급 해드려요!
// 차비 / 친구 / 만근비등 여러가지 이벤트 진행하고 있어요!

// Q
// 대기실운영도 하고있어요
// (고데기, 드라이기, 화장대, 컵라면 커피 음료수등)
// 홀복, 구두 지원(총30벌, 50켤레 이상 사이즈별로 준비)

// Q.숙식도 가능한가요?
// 개인숙소 지원하기 떄문에 타지나 지방에 있어도 고민하지 말고 올라와서 일하면되요!

// 4. 진상절대금지

// Q. 진상 손님이 많은가요?
// 술 강요 , 터치 등 진상손님은 절대 받지 않아요
// 진상이면 가게 눈치볼 필요없이 나오시면되요

// Q. 술을 잘 못마시는데 괜찮을까요?
// 한잔도 안마시고 일하는 언니들 많아요

// Q. 노출을 강요하는 업소인가요?
// 수위 / 인사 / 셔츠등의 퇴페 절대 아니에요!

// 시스템 지원
