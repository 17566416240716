import {dataParam} from '@/pages';
import {Dialog, Transition} from '@headlessui/react';
import Image from 'next/image';
import {Fragment} from 'react';
// import Svgic_close_25 from '@assets/svgs/ic_close_25.svg';
// import {FieldErrors, useForm} from 'react-hook-form';
// import {Carousel} from 'react-responsive-carousel';
// import OkButton from '@components/Button';
// import {cls} from 'utils';
import Slider from 'react-slick';
interface Props {
  visibleDialog: boolean;
  data: dataParam;
  // setVisibleDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onClickOk?: (text: string) => void;
  onClickCancel: () => void;

  //   DetailData?: Partial<any>;
}

type FormProps = {
  Memo: string;
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
};

export default function DialogMenu({
  visibleDialog,
  data,
  onClickOk,
  onClickCancel,
}: //   DetailData = {},
Props) {
  //   const {
  //     register,
  //     handleSubmit,
  //     reset,
  //     watch,
  //     formState: {errors},
  //   } = useForm<FormProps>();

  //   const onValid = (formData: FormProps) => {
  //     if (formData.Memo.length >= 500) {
  //       return alert('최대 500자 입력가능합니다.');
  //     }

  //     onClickOk(formData.Memo);
  //   };

  //   const onInValid = (errors: FieldErrors<FormProps>) => {
  //     if (errors.Memo) {
  //       return alert(errors.Memo.message);
  //     }
  //   };

  //   const Memo = watch('Memo');

  return (
    <>
      <Transition appear show={visibleDialog} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[800px] transform overflow-hidden transition-all">
                  <div
                    // onSubmit={handleSubmit(onValid, onInValid)}
                    className="mt-[10px] h-full  w-full rounded-lg bg-white  pt-2 text-left"
                  >
                    <div className="mb-2 flex justify-end  px-5">
                      <button
                        onClick={() => {
                          onClickCancel();
                        }}
                        className={' h-10 w-10 text-lg'}
                        type={'button'}
                      >
                        x
                      </button>
                    </div>
                    <div className=" h-full  overflow-auto px-5 pb-10 ">
                      {/* <div className=" text-okGray-900 text-base font-bold">
                        {data.title}
                      </div> */}
                      {/* <div className="border-t-okGray-400 mt-[10px] border-t" /> */}

                      {data.type === 'images' ? (
                        <Slider {...settings} className="mt-2">
                          {data.images.map((value: any, index) => {
                            return (
                              <div key={index}>
                                <div className="my-4 flex justify-center text-2xl">
                                  {value.name}
                                </div>
                                <Image
                                  alt="images"
                                  placeholder="blur"
                                  src={value.url}
                                />
                              </div>
                            );
                          })}
                        </Slider>
                      ) : (
                        <>
                          {data.contents?.map((value, index) => {
                            return (
                              <div key={index} className={''}>
                                <div className=" text-base font-bold text-[#2E3642]">
                                  {value.question}
                                </div>
                                <div className=" border-t" />
                                <div className="mb-6 mt-2 rounded-lg bg-[#DFE5EF] px-2 py-2 text-sm">
                                  <div
                                    className="text-[#2E3642]"
                                    dangerouslySetInnerHTML={{
                                      __html: value.answer,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>

                    {/* <div className=" text-okGray-900 text-base font-bold">
                      Q. 출근이 자유로운 편인가요?
                    </div>

                    <div className="border-t-okGray-400 mt-[10px] border-t" />

                    <div className="mb-6 mt-[20px] space-y-4">
                      <div className="text-okGray-700 text-sm">
                        출근하셔서 하고싶은 만큼
                        <span className="text-[#EA5456]">자유롭게</span>{' '}
                        일하시면 되요!
                      </div>
                      <div className="text-okGray-700 text-sm">
                        원할때 언제든 원하는 만큼 체력 닿는데까지 일잡아
                        드릴꼐요!
                      </div>
                    </div> */}

                    {/* <div className=" text-okGray-900 text-base font-bold">
                      Q. 일은 얼마나 있죠?
                    </div>

                    <div className="border-t-okGray-400 mt-[10px] border-t" />

                    <div className="mt-[20px] space-y-4">
                      <div className="text-okGray-700 text-sm">
                        오전, 오후에도 일, 콜 많고 주간조 언니들 많아요!
                      </div>
                      <div className="text-okGray-700 text-sm">
                        일이 너무 많아서 손님대비 언니들이 부족해요!
                      </div>
                    </div> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
