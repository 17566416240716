import Link from 'next/link';
import {useEffect} from 'react';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FunctionComponent<LayoutProps> = ({children}) => {
  return (
    <div className="mx-auto max-w-2xl bg-white">
      <div className="h-3/4 overflow-auto ">{children}</div>
      <div className="flex h-1/4 flex-col space-y-2 bg-[#EDEEF2] px-2 py-1">
        <div className="flex flex-1 items-center justify-center rounded-lg bg-[#06C755] active:bg-[#06C755]/50">
          라인 24callme 검색!
        </div>
        <Link
          href={'https://open.kakao.com/o/sICIkZgf'}
          className="flex flex-1 items-center justify-center rounded-lg bg-[#F7E600] "
        >
          <div className="">오픈채팅 상담하기</div>
        </Link>
        <a
          className="flex flex-1 items-center justify-center rounded-lg bg-[#F2D091]"
          href="tel:010-9798-2669"
        >
          전화연결 클릭!
        </a>
      </div>
    </div>
  );
};

export default Layout;
